import Select from '@paljs/ui/Select';
import { Card, CardBody } from '@paljs/ui/Card';
import { InputGroup } from '@paljs/ui/Input';
import { Button } from '@paljs/ui/Button';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import axios from 'axios';
import { EvaIcon } from '@paljs/ui/Icon';
import { GlobalStateData } from '../../storage/GlobalDataProvider';
import '../../styles/common.css';
import { classNames } from 'react-select/src/utils';
import {
  requireCheck,
  resetRequired,
  pullDownReset,
  allowOnlyHW,
  baseUri,
  showLoader,
  hideLoader,
  numFormatter,
  checkNumber,
  authCheck,
  ADMIN,
  urlHeadingMap,
  getPageAccessFromState,
} from '../../utils/utils';
import { getFiltered } from '../../utils/table';
import DataTable from 'react-data-table-component';
import toast, { Toaster } from 'react-hot-toast';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const ActionBtn = styled(Button)`
  padding: 4px;
  margin-bottom: 10px;
`;

const SelectField = styled(Select)`
  margin-bottom: 10px;
`;

const InputPage = () => {
  const [userSortData, setUserSortData] = useState();
  const state = useContext(GlobalStateData);
  const [defaultUser, setDefaultUser] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [defaultSort, setDefaultSort] = useState([]);
  const [sortOptions, setSortOptions] = useState([]);
  const [sortOptionsAll, setSortOptionsAll] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableFilter, setTableFilter] = useState('');
  const pageAccess = getPageAccessFromState(state);
  const [isSave, setSave] = useState(pageAccess.write === 1);
  useEffect(() => {
    authCheck(state.authData);
    getApi();
    getCustomers();
    getSorts();
  }, []);

  const getApi = () => {
    showLoader();
    axios
      .get(baseUri() + 'user-sorts?populate=*&sort[0]=id:desc', {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setUserSortData(response.data.data);
        if (tableFilter) {
          filterChange(tableFilter, response.data.data);
        } else {
          setTableData(response.data.data);
        }
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const getCustomers = () => {
    axios
      .get(baseUri() + 'users?filters[userType][$eq]=4', {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let options = [];
        response.data.forEach(function (item, index) {
          options.push({ label: item.userCode + ' - ' + item.name, value: item.id });
        });
        setUserOptions(options);
      })
      .catch((error) => {
        //return  error;
      });
  };

  const getSorts = () => {
    axios
      .get(baseUri() + 'sorts', {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let options = [];
        response.data.data.forEach(function (item, index) {
          options.push({ label: item.attributes.sortCode, value: item.id });
        });
        setSortOptions(options);
        setSortOptionsAll(options);
      })
      .catch((error) => {
        //return  error;
      });
  };

  const [sortName, setSortName] = useState('');
  const [user, setUser] = useState('');
  const [sort, setSort] = useState('');
  const [pickId, setPickId] = useState('');

  const handleChange = (event) => {
    resetRequired(event);
    allowOnlyHW();
    switch (event.target.name) {
      case 'sortName':
        let val = event.target.value;
        val = val
          .split('')
          .filter((t) => t !== ' ')
          .join('');
        setSortName(val);
        let inputValue = val;
        let filterFlg = '';
        if (inputValue) {
          if (inputValue.charAt(0) == 'W' || inputValue.charAt(0) == 'H') {
            filterFlg = inputValue.charAt(0);
          }
        }
        filterSortOptions(filterFlg);
        break;
    }
  };

  const filterSortOptions = (filterKey) => {
    if (filterKey) {
      let optionAll = sortOptionsAll;
      let options = [];
      optionAll.forEach(function (item, index) {
        if (item.label.charAt(0) == filterKey) options.push({ label: item.label, value: item.value });
      });
      setSortOptions(options);
    } else {
      setSortOptions([]);
    }
  };

  const userChange = (event) => {
    setUser(event.value);
    setDefaultUser({ label: event.label, value: event.value });
    pullDownReset('user');
  };

  const sortChange = (event) => {
    setSort(event.value);
    setDefaultSort({ label: event.label, value: event.value });
    pullDownReset('sort');
  };

  const clearFormData = () => {
    setSave(pageAccess.write === 1);
    setSortName('');
    setSort('');
    setUser('');
    setDefaultUser([]);
    setDefaultSort([]);
    setPickId('');
    resetRequired();
  };

  const checkDuplicate = () => {
    const postData = {
      data: {
        sortName,
        user,
        sort,
      },
    };
    let sortCodeValid = allowOnlyHW(true);
    let validate = requireCheck(postData, userSortData, 'sortName', pickId);
    if (!validate || !sortCodeValid) {
      return false;
    }
    if (pickId) {
      postApi();
      return;
    }
    showLoader();
    axios
      .get(`${baseUri()}customer-sort/duplicate?sort=${sort}&sortName=${sortName}`, {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        hideLoader();
        if (!response.data.data) {
          toast.dismiss();
          toast.error('Sort Name or Sort already used');
        } else {
          postApi();
        }
      });
  };

  const postApi = () => {
    const postData = {
      data: {
        sortName,
        user,
        sort,
      },
    };
    if (pickId) {
      axios
        .put(baseUri() + 'user-sorts' + '/' + pickId, postData, {
          headers: {
            authorization: 'Bearer ' + state.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          clearFormData();
          getApi();
        })
        .catch((error) => {
          //return  error;
        });
    } else {
      axios
        .post(baseUri() + 'user-sorts', postData, {
          headers: {
            authorization: 'Bearer ' + state.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          clearFormData();
          getApi();
        })
        .catch((error) => {
          //return  error;
        });
    }
  };
  const editUserSort = (id) => {
    showLoader();
    clearFormData();
    axios
      .get(baseUri() + `user-sorts/${id}?populate=*`, {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        response = response.data.data;
        setSave(pageAccess.edit === 1);
        setSortName(response.attributes.sortName);
        setDefaultSort({
          label: response?.attributes?.sort?.data?.attributes?.sortCode,
          value: response?.attributes?.sort?.data?.id,
        });
        setSort(response?.attributes?.sort?.data?.id);
        setDefaultUser({
          label:
            response?.attributes?.user?.data?.attributes?.userCode +
            ' - ' +
            response?.attributes?.user?.data?.attributes?.name,
          value: response?.attributes?.user?.data?.id,
        });
        setUser(response?.attributes?.user?.data?.id);
        setPickId(response.id);
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const deleteUserSort = (id) => {
    const removeGroup = window.confirm('Would you like to delete this customer sort?');
    if (removeGroup) {
      showLoader();
      setPickId(id);
      axios
        .delete(baseUri() + 'user-sorts/' + id, {
          headers: {
            authorization: 'Bearer ' + state.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          clearFormData();
          getApi();
        })
        .catch((error) => {
          //return  error;
        });
    }
  };

  const columns = [
    {
      name: 'Action',
      cell: (row) => (
        <div>
          <ActionBtn
            data-tag="allowRowEvents"
            className="abtn-color"
            onClick={() => {
              editUserSort(row.id);
            }}
          >
            {' '}
            <EvaIcon name="edit" />{' '}
          </ActionBtn>
          {pageAccess.delete === 1 && (
            <ActionBtn
              data-tag="allowRowEvents"
              className="abtndel-color"
              onClick={() => {
                deleteUserSort(row.id);
              }}
            >
              {' '}
              <EvaIcon name="trash" />{' '}
            </ActionBtn>
          )}
        </div>
      ),
    },
    {
      name: 'Sort Name',
      selector: (row) => `${row.attributes.sortName}`,
      sortable: true,
      maxWidth: '70%',
    },
    {
      name: 'User',
      selector: (row) =>
        `${
          row?.attributes?.user?.data
            ? row?.attributes?.user?.data?.attributes?.userCode + ' - ' + row?.attributes?.user?.data?.attributes?.name
            : ''
        }`,
      sortable: true,
      maxWidth: '70%',
    },
    {
      name: 'Sort',
      selector: (row) => `${row?.attributes?.sort?.data?.attributes?.sortCode}`,
      sortable: true,
      maxWidth: '70%',
    },
  ];
  const filterColumns = [
    'sortName',
    'user.data.attributes.userCode',
    'user.data.attributes.name',
    'sort.data.attributes.sortCode',
  ];

  const filterChange = (searchInput, newData = false) => {
    setTableFilter(searchInput);
    let available = [];
    if (newData) {
      available = newData;
    } else {
      available = userSortData;
    }
    if (searchInput) {
      let filteredData = getFiltered(searchInput, available, filterColumns);
      setTableData(filteredData);
    } else {
      setTableData(available);
    }
  };

  return (
    <>
      <SEO title="Customer Sort" />
      <Toaster />
      <Row>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header>Customer Sort</header>
            <CardBody className="cardbody">
              <Row>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    Sort Name <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      onChange={(e) => handleChange(e)}
                      placeholder="Sort Name *"
                      name="sortName"
                      value={sortName}
                      maxLength="30"
                      className="sortName restrictHW require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    Select User <span className="red">*</span>
                  </label>
                  <SelectField
                    fullWidth
                    onChange={(e) => userChange(e)}
                    options={userOptions}
                    id="user"
                    name="user"
                    placeholder="Select User *"
                    value={defaultUser}
                    className="pull-down require"
                  />
                </Col>
                <Col breakPoint={{ xs: 12, md: 4 }}>
                  <label>
                    Select Sort <span className="red">*</span>
                  </label>
                  <SelectField
                    fullWidth
                    onChange={(e) => sortChange(e)}
                    options={sortOptions}
                    id="sort"
                    name="sort"
                    placeholder="Select Sort *"
                    value={defaultSort}
                    className="pull-down require"
                  />
                </Col>
              </Row>
              <Button
                status="Success"
                disabled={!isSave}
                type="button"
                className="btnrad"
                shape=""
                halfWidth
                onClick={checkDuplicate}
              >
                Save
              </Button>
              &nbsp; &nbsp;
              <Button status="danger" type="button" className="btnrad" shape="" halfWidth onClick={clearFormData}>
                Reset
              </Button>
            </CardBody>
          </Card>
        </Col>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header>User Sort</header>
            <CardBody>
              <Input fullWidth size="Small">
                <input
                  type="text"
                  onChange={(e) => filterChange(e.target.value)}
                  placeholder="Filter"
                  name="tableFilter"
                  value={tableFilter}
                />
              </Input>
              <DataTable
                columns={columns}
                data={tableData}
                fixedHeader
                allowOverflow={false}
                overflowY={false}
                overflowX={false}
                wrap={false}
                highlightOnHover={true}
                pagination={true}
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default InputPage;
